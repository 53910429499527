import './App.css';
import bg from './assets/images/others/img_promocional.png';

function Principal() {

	return (
		<div className='container d-flex justify-content-center align-items-center min-vh-100'>
				<div className='col-md-6 content-center'>
					<img src={bg} alt='' className='rounded-start-4 rounded-end-5 responsive-img'></img>
				</div>
		</div>
	);
}

export default Principal;
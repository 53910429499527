import './App.css';
import axios from "axios";
import Swal from 'sweetalert2';
import logo from './assets/images/logo/logo.png';
import Informacoes from './informacoes';
import { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Importar() {

	const [evento, setEvento] = useState([]);

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_URL}/dados/evento`)
			.then(response => {
				setEvento(response.data[0]);
			});
	}, []);

	//Chamar rota de importar
	const importarArquivo = async (values) => {
		try {
			const arquivoInput = document.querySelector('#lista');
			const formData = new FormData();
			formData.append('arquivo', arquivoInput.files[0]); // Quando tem apenas um input de arquivo.

			await axios.post(`${process.env.REACT_APP_API_URL}/importar/arquivo`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then((response => {
				Swal.fire({
					title: response.data.title,
					text: response.data.text,
					icon: response.data.icon,
					confirmButtonText: response.data.confirmButtonText
				});
			}));
		}
		catch (error) {
			console.log('Falha: ', error);
		}
	};

	return new Date() > new Date(evento.data_limite_importacao) ? (
		<Informacoes />
	) :
		(
			<div className='container d-flex justify-content-center align-items-center min-vh-100 mr-2 pr-2'>
				<div className='col-md-6 content-center'>
					<div className="max-w-sm rounded overflow-hidden shadow-lg pr-4 pl-4">
						<div className="px-6 py-4 bg-white">
							<div className="p-2 ">
								<div className="font-bold mb-2">
									<img src={logo} className="mx-auto d-block logo" alt="..." />
									<h4 className='title text-center'>Importe a lista de participantes</h4>
								</div>
								<form encType="multipart/form-data" method="post">
									<Container>
											<Row>
												<Col sm={9}>
													<input className='form-control w-full mt-1' name="lista" type="file" id="lista" />
												</Col>
												<Col sm={3}>
													<div className=''>
														<button id="btn-importar" onClick={importarArquivo} type='button' className='btn btn-primary mr-1 pr-1 mb-2 mt-1'>
															<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-up" viewBox="0 0 16 16">
																<path fillRule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5m-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5" />
															</svg>
															Importar
														</button>
													</div>
												</Col>
											</Row>
											<Row>
												<hr className='mt-4 mr-6 ml-6'></hr>
												<p style={{fontSize:"13px"}} className=''>
													- Para adicionar mais partipantes, basta submeter a planilha no padrão esperado pelo sistema contendo os novos participantes.
												</p>
												<p style={{fontSize:"13px"}} className=''>
													- Em caso de erro, verificar se a planilha submetida possui a primeira linha e se os campos desta linha estão todos em minúsculo.
												</p>											</Row>
												<p style={{fontSize:"13px"}} className=''>
													- Por favor, preencher todos os campos, não submeter campos em branco.
												</p>
									</Container>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
}

export default Importar;
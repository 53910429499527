import './App.css';
import axios from "axios";
import Swal from 'sweetalert2';
import logo from './assets/images/logo/logo.png';
import * as XLSX from 'xlsx';


function Exportar() {

	const exportar = (values) => {
		const tabela = document.getElementById('minha-tabela');
		const workbook = XLSX.utils.table_to_book(tabela, { raw: true });

		//converter cpf,pa(campos numericos) para texto especificamente para planilha excel.
		let worksheet = workbook.Sheets[workbook.SheetNames[2]];

		if (worksheet && worksheet['!ref']) {
			let range = XLSX.utils.decode_range(worksheet['!ref']);
			// Colunas que você quer converter para string
			let columnsToConvert = [2, 4, 7];
			for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
				columnsToConvert.forEach(colIndex => {
					let cellAddress = { c: colIndex, r: rowNum };
					let cellRef = XLSX.utils.encode_cell(cellAddress);
					if (worksheet[cellRef]) {
						worksheet[cellRef].t = 's'; // Define o tipo como string
					}
				});
			}
		} else {
			console.log('n definido');
		}

		XLSX.writeFile(workbook, 'tabela.xlsx');
	}

	async function gerarTable() {
		try {


			const resultado = await axios.post(`${process.env.REACT_APP_API_URL}/dados/exportar/inscritos`, {
				key: document.getElementById('senha').value
			});

			if (resultado) {

				let tabelaVisivel = document.getElementById('minha-tabela');
				let btnExportar = document.getElementById('btn-exportar');
				btnExportar.removeAttribute('hidden');
				tabelaVisivel.removeAttribute('hidden');

				while (tabelaVisivel.rows.length > 1) {
					tabelaVisivel.deleteRow(1);
				}

				let tbody = document.getElementById('tbody');
				for (const item of resultado.data) {
					const novaLinha = document.createElement('tr');

					const celulaNome = document.createElement('td');
					celulaNome.innerText = item.nome ? item.nome : '';
					novaLinha.appendChild(celulaNome);

					const celulaEmail = document.createElement('td');
					celulaEmail.innerText = item.email ? item.email : '';
					novaLinha.appendChild(celulaEmail);

					const celulaCpf = document.createElement('td');
					celulaCpf.innerText = item.cpf ? item.cpf.toString() : '';
					novaLinha.appendChild(celulaCpf);

					const celulaSexo = document.createElement('td');
					celulaSexo.innerText = item.sexo ? item.sexo : '';
					novaLinha.appendChild(celulaSexo);

					const celulaCelular = document.createElement('td');
					celulaCelular.innerText = item.celular ? item.celular.toString() : '';
					novaLinha.appendChild(celulaCelular);

					const celulaLogin = document.createElement('td');
					celulaLogin.innerText = item.login_sisbr ? item.login_sisbr.toString() : '';
					novaLinha.appendChild(celulaLogin);

					const celulaCooperativa = document.createElement('td');
					celulaCooperativa.innerText = item.cooperativa ? item.cooperativa : '';
					novaLinha.appendChild(celulaCooperativa);

					const celulaPa = document.createElement('td');
					celulaPa.innerText = item.pa ? item.pa.toString() : '';
					novaLinha.appendChild(celulaPa);

					const celulaCargo = document.createElement('td');
					celulaCargo.innerText = item.cargo ? item.cargo : '';
					novaLinha.appendChild(celulaCargo);

					const celulaCamisa = document.createElement('td');
					celulaCamisa.innerText = item.camisa ? item.camisa : '';
					novaLinha.appendChild(celulaCamisa);

					const celulaCidade = document.createElement('td');
					celulaCidade.innerText = item.cidade ? item.cidade : '';
					novaLinha.appendChild(celulaCidade);

					const celulaUf = document.createElement('td');
					celulaUf.innerText = item.uf ? item.uf : '';
					novaLinha.appendChild(celulaUf);

					const celulaData = document.createElement('td');
					celulaData.innerText = item.data_inscricao ? item.data_inscricao : '';
					novaLinha.appendChild(celulaData);

					tbody.appendChild(novaLinha);
				}
			} else {
				Swal.fire({
					title: 'Erro',
					text: 'Key inválida',
					icon: 'error',
					confirmButtonText: 'Fechar'
				});
				let tabelaVisivel = document.getElementById('minha-tabela');
				while (tabelaVisivel.rows.length > 1) {
					tabelaVisivel.deleteRow(1);
				}
				let btnExportar = document.getElementById('btn-exportar');
				btnExportar.setAttribute('hidden');
			}
		} catch (error) {
			Swal.fire({
				title: 'Erro',
				text: 'Key inválida',
				icon: 'error',
				confirmButtonText: 'Fechar'
			});
			let tabelaVisivel = document.getElementById('minha-tabela');
			while (tabelaVisivel.rows.length > 1) {
				tabelaVisivel.deleteRow(1);
			}
			let btnExportar = document.getElementById('btn-exportar');
			btnExportar.setAttribute('hidden', 'hidden');
		}
	}



	return (
		<div className='flex justify-center items-center min-h-screen bg-gray-100'>
			<div className='w-full max-w-sm bg-white border rounded-4 p-4 shadow-lg'>
				<div className='text-center mb-4'>
					<img src={logo} className="mx-auto logo" alt="Logo" />
					<h4 className='title'>Exportar</h4>
				</div>

				<div className='grid grid-cols-10 gap-2 mb-4'>
					<div className='col-span-4'>
						<input className='form-control w-full' placeholder='Key' type='password' name="senha" id="senha" />
					</div>
					<div className='col-span-2 mt-2'>
						<button type='button' onClick={gerarTable} className='btn btn-success w-full'>Gerar</button>
						<button id="btn-exportar" hidden type='button' onClick={exportar} className='btn btn-warning float-end mb-2'>
							Exportar
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-xlsx" viewBox="0 0 16 16">
								<path fillRule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
							</svg>
						</button>
					</div>
				</div>

				<div className='overflow-x-auto'>
					<table className="min-w-full text-left text-sm font-light" hidden id="minha-tabela">
						<thead className="border font-medium bg-slate-100">
							<tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100">
								<th className="border px-3 py-2">Nome</th>
								<th className="border px-3 py-2">Email</th>
								<th className="border px-3 py-2">Cpf</th>
								<th className="border px-3 py-2">Sexo</th>
								<th className="border px-3 py-2">Celular</th>
								<th className="border px-3 py-2">Login sisbr</th>
								<th className="border px-3 py-2">Cooperativa</th>
								<th className="border px-3 py-2">Pa</th>
								<th className="border px-3 py-2">Cargo</th>
								<th className="border px-3 py-2">Camisa</th>
								<th className="border px-3 py-2">Cidade</th>
								<th className="border px-3 py-2">Uf</th>
								<th className="border px-3 py-2">Data de inscricao</th>
							</tr>
						</thead>
						<tbody className="text-xs" id="tbody">

						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default Exportar;
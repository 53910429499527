import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Exportar from './exportar'; // Importe o componente da nova página
import Principal from './principal'; // Importe o componente da nova página
import Importar from './importar'; // Importe o componente da nova página
import Credenciamento from './credenciamento'; // Importe o componente da nova página



const RoutesConfig = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<Principal />} />
      <Route path="/exportar" element={<Exportar />} />
      <Route path="/inscricao" element={<App />} />
      <Route path="/importar" element={<Importar />} />
      <Route path="/credenciamento" element={<Credenciamento />} />
    </Routes>
  </Router>
);

export default RoutesConfig;
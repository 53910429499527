import './App.css';
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field } from 'formik';
import axios from "axios";
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import bg from './assets/images/others/img_promocional.png';
import logo from './assets/images/logo/logo.png';
import Informacoes from './informacoes';


function App() {
	// :: VARIÁVEIS ::
	const recaptchaRef = useRef(null);
	const [token, setToken] = useState("");
	const [ufs, setUfs] = useState([]);
	const [selectedUf, setSelectedUf] = useState('');
	const [cidades, setCidades] = useState([]);
	const [cpf, setCpf] = useState('');
	const [celular, setCelular] = useState('');
	const [coops, setCoops] = useState([]);
	const [selectedCoop, setSelectedCoop] = useState('');
	const [pas, setPas] = useState([]);
	const [evento, setEvento] = useState([]);

	// const isExternal = ['10', '11', '12', '13'].includes(selectedCoop);
	const isExternal = ['10', '11', '12', '13'];

	// :: VALIDAÇÃO GOOGLE RECAPTCHA ::
	const onChangeRecaptcha = (recaptchaToken) => setToken(recaptchaToken);

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_URL}/dados/evento`)
			.then(response => {
				setEvento(response.data[0]);
			});
	}, []);

	// :: RESGATAR DADOS DA API DO IBGE PARA PEGAR ESTADOS ::
	useEffect(() => {
		axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
			.then(response => {
				setUfs(response.data);
			});
	}, []);

	// :: RESGATAR DADOS DA API DO IBGE PARA PEGAR MUNICÍPIOS DE ACORDO COM O ESTADO ::
	useEffect(() => {
		if (selectedUf) {
			axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`)
				.then(response => {
					setCidades(response.data);
				});
		}
	}, [selectedUf]);

	// :: RESGATAR DADOS SOBRE AS COOPERATIVAS ::
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_URL}/dados/cooperativas`)
			.then(response => {

				setCoops(response.data.filter(item => [10, 11, 12, 13].includes(item.id)));
			});
	}, []);

	// :: RESGATAR DADOS DAS PAS DE ACORDO COM A COOPERATIVA
	useEffect(() => {
		if (selectedCoop) {
			axios.get(`${process.env.REACT_APP_API_URL}/dados/cooperativas/${selectedCoop}`)
				.then(response => {
					setPas(response.data);
				});
		}
	}, [selectedCoop]);

	// :: PEGAR O ESTADO SELECIONADO ::
	const handleChangeUf = (e) => {
		const uf = e.target.value;
		setSelectedUf(uf);
	};

	// :: RETIRAR A PONTUAÇÃO DO CPF PARA REPASSAR PARA O BACKEND ::
	const handleChangeCpf = (e) => {
		const cpf = e.target.value.replace(/\D/g, '');
		setCpf(cpf);
	};

	// :: RETIRAR A PONTUAÇÃO DO NÚMERO DE CELULAR PARA REPASSAR PARA O BACKEND ::
	const handleChangeCelular = (e) => {
		const celular = e.target.value.replace(/\D/g, '');
		setCelular(celular);
	};

	// :: SELECIONAR A COOPERATIVA PARA PESQUISAR OS PAS ::
	const handleChangeCoop = (e) => {
		const cooperativa = e.target.value;
		setSelectedCoop(cooperativa);
	}

	function validarCpf(cpf) {
		cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

		if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
			return false; // Verifica se o CPF tem 11 dígitos e se todos os dígitos são iguais
		}

		let soma = 0;
		let resto;

		for (let i = 1; i <= 9; i++) {
			soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
		}

		resto = (soma * 10) % 11;

		if ((resto === 10) || (resto === 11)) {
			resto = 0;
		}

		if (resto !== parseInt(cpf.substring(9, 10))) {
			return false;
		}

		soma = 0;

		for (let i = 1; i <= 10; i++) {
			soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
		}

		resto = (soma * 10) % 11;

		if ((resto === 10) || (resto === 11)) {
			resto = 0;
		}

		if (resto !== parseInt(cpf.substring(10, 11))) {
			return false;
		}

		return true;
	}
	// // :: ENVIAR OS DADOS DO FORMULÁRIO PARA O BACKEND ::
	const handleClickSubscribe = async (values) => {
		try {
			// validar cpf, caso inválido, retornar e impedir submit
			const cpfValidado = validarCpf(cpf);
			if (cpfValidado === false) {
				Swal.fire({
					title: 'CPF Inválido!',
					icon: 'error',
					confirmButtonText: 'Fechar'
				});
				setCpf('');
			} else if (new Date() > new Date(evento.data_limite_inscricao)) {
				Swal.fire({
					title: 'Falha ao realizar inscrição, as inscrições estão finalizadas!',
					icon: 'error',
					confirmButtonText: 'Fechar'
				});
				window.location.reload(true);
			} else {
				if (token) {
					await axios.post(`${process.env.REACT_APP_API_URL}/inscricao`, {
						token,
						nome: values.nome,
						cpf: cpf,
						sexo: values.sexo,
						celular: celular,
						email: values.email,
						login_sisbr: values.login_sisbr,
						cooperativa: coops.find(coop => coop.id === +selectedCoop).cooperativa,
						pa: values.pa,
						cargo: values.cargo,
						camisa: values.camisa,
						cidade: values.cidade,
						uf: selectedUf
					})
						.then((response => {
							Swal.fire({
								title: response.data.title,
								text: response.data.text,
								icon: response.data.icon,
								confirmButtonText: response.data.confirmButtonText
							});
						}));
					setToken('');
					if (recaptchaRef.current) {
						recaptchaRef.current.reset();
					}
					// recaptchaRef.current?.reset();
				}
				else {
					Swal.fire({
						title: 'Necessário confirmar a validação ReCAPTCHA',
						icon: 'error',
						confirmButtonText: 'Fechar'
					});
				};
			}
		}
		catch (error) {
			console.log('Falha: ', error);
		}
	};

	return new Date() > new Date(evento.data_limite_inscricao) ? (
		<Informacoes />
	) :
		(
			<div className='container d-flex justify-content-center align-items-center min-vh-100'>
				<div className='row border rounded-4 p-2'>
					<div className='col-md-6 left-pannel'>
						<img src={bg} alt='' className='rounded-start-4 img-promocional'></img>
					</div>
					<div className='col-md-6 border rounded-end-4 right-pannel'>
						<img src={logo} className="mx-auto d-block logo" alt="..." />
						<h4 className='title text-center'>Inscrição</h4>
						<Formik
							initialValues={{}}
							onSubmit={handleClickSubscribe}
						>
							<Form autoComplete="off">
								<div className='row m-2'>
									<h5 className='text-center'>Informações da Cooperativa</h5>
									<div className="col-md-12 mb-1">
										<label htmlFor="cooperativa" className="form-label">Qual a sua cooperativa?</label>
										<Field name="cooperativa" as="select" className="form-control" defaultValue='' onChange={handleChangeCoop}>
											<option value="" disabled>Selecione...</option>
											{coops.map(coop => (
												<option key={coop.id} value={coop.id}>{coop.cooperativa}</option>
											))}
										</Field>
									</div>
									{!isExternal && (
										<>
											<div className="col-md-4 mb-2">
												<label htmlFor="pa" className="form-label">Qual o seu PA?</label>
												<Field name="pa" as="select" className="form-control" defaultValue='' required>
													<option value="" disabled>Selecione...</option>
													{pas.map(pa => (
														<option key={pa.id} value={pa.pa}>{pa.pa}</option>
													))}
												</Field>
											</div>
											<div className="col-md-8 mb-2">
												<label htmlFor="cargo" className="form-label">Qual o seu cargo?</label>
												<Field name="cargo" as="select" className="form-control" defaultValue='' required>
													<option value="" disabled>Selecione...</option>
													<option value="Analista">Analista</option>
													<option value="Assistente">Assistente</option>
													<option value="Auxiliar">Auxiliar</option>
													<option value="Consultor">Consultor</option>
													<option value="Coordenador">Coordenador</option>
													<option value="Diretor">Diretor</option>
													<option value="Gerente">Gerente</option>
													<option value="Líder">Líder</option>
													<option value="Presidente">Presidente</option>
													<option value="Superintendente">Superintendente</option>
													<option value="Supervisor">Supervisor</option>
												</Field>
											</div>
										</>
									)}
									<h5 className='text-center'>Informações pessoais</h5>
									<div className="col-md-12 mb-1">
										<label htmlFor="nome" className="form-label">Nome completo</label>
										<Field maxLength='75' name="nome" className="form-control" placeholder="Nome completo" required></Field>
									</div>
									<div className="col-md-3 mb-1">
										<label htmlFor="sexo" className="form-label">Sexo</label>
										<Field name="sexo" as="select" className="form-control" defaultValue='' required>
											<option value="" disabled>Selecione...</option>
											<option value="Masculino">Masculino</option>
											<option value="Feminino">Feminino</option>
										</Field>
									</div>
									<div className="col-md-4 mb-1">
										<label htmlFor="cpf" className="form-label">CPF</label>
										<InputMask name="cpf"
											mask="999.999.999-99"
											value={cpf}
											onChange={handleChangeCpf}
											className="form-control"
											placeholder="Apenas números"
											inputMode="numeric"
											required>
										</InputMask>
									</div>
									{!isExternal && (
										<div className="col-md-5 mb-1">
											<label htmlFor="login_sisbr" className="form-label">Login Sisbr</label>
											<Field maxLength='50' name="login_sisbr" className="form-control" placeholder="Login Sisbr" required></Field>
										</div>
									)}
									<div className="col-md-4 mb-3">
										<label htmlFor="uf" className="form-label">UF</label>
										<Field name="uf" as="select" className="form-control" defaultValue='' onChange={handleChangeUf} required>
											<option value="" disabled>Selecione...</option>
											{ufs.map(uf => (
												<option key={uf.id} value={uf.sigla}>{uf.nome}</option>
											))}
										</Field>
									</div>
									<div className="col-md-5 mb-2">
										<label htmlFor="cidade" className="form-label">Cidade</label>
										<Field maxLength='50' name="cidade" as="select" className="form-control" defaultValue='' required>
											<option value="" disabled>Selecione...</option>
											{cidades.map(cidade => (
												<option key={cidade.nome} value={cidade.nome}>{cidade.nome}</option>
											))}
										</Field>
									</div>
									<div className="col-md-3 mb-2">
										<label htmlFor="camisa" className="form-label">Camisa</label>
										<Field name="camisa" as="select" className="form-control" defaultValue='' required>
											<option value="" disabled>Selecione...</option>
											<option value="PP">PP</option>
											<option value="P">P</option>
											<option value="M">M</option>
											<option value="G">G</option>
											<option value="GG">GG</option>
											<option value="XG">XG</option>
											<option value="EGG">EGG</option>
											<option value="EXG">EXG</option>
										</Field>
									</div>
									<h5 className='text-center'>Informações de contato</h5>
									<div className="col-md-8 mb-2">
										<label htmlFor="email" className="form-label">E-mail corportativo</label>
										<Field maxLength='75' name="email" type='email' className="form-control" placeholder="mail@mail.com.br" required></Field>
									</div>
									<div className="col-md-4 mb-4">
										<label htmlFor="celular" className="form-label">Celular</label>
										<InputMask name="celular"
											mask="(99)99999-9999"
											value={celular}
											onChange={handleChangeCelular}
											className="form-control"
											placeholder="Apenas números"
											inputMode="numeric"
											required>
										</InputMask>
									</div>
									<div className="col-md-6 mb-2">
										<ReCAPTCHA
											sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
											onChange={onChangeRecaptcha}
											ref={recaptchaRef}
										/>
									</div>
									<div className='col-md-6 mb-2 d-flex justify-content-end align-items-center'>
										<button type="submit" className="btn btn-success float-end">Inscreva-se</button>
									</div>
								</div>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
		);
}

export default App;
import './App.css';
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field } from 'formik';
import axios from "axios";
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import bg from './assets/images/others/img_promocional.png';
import logo from './assets/images/logo/logo.png';


function Informacoes() {
	// :: VARIÁVEIS ::
	const [token, setToken] = useState("");
	const [ufs, setUfs] = useState([]);

	return (
		<div className='container d-flex justify-content-center align-items-center min-vh-100'>
			<div className='row border rounded-4 p-2'>
				<div className='col-md-6 left-pannel'>
					<img src={bg} alt='' className='rounded-start-4 img-promocional'></img>
				</div>
				<div className='col-md-6 border rounded-end-4 right-pannel'>
					<img src={logo} className="mx-auto d-block logo" alt="..." />
					<h4 className='title text-center'>Inscrições finalizadas</h4>
					
				</div>
			</div>
		</div>
	);
}

export default Informacoes;